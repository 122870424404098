$montserrat:'montserrat', sans-serif;
$open_sans:'open Sans', sans-serif;
$font_color1:#525976;
$font_color2:#787f94;
$white:#ffffff;
$grey:#f1f1f1;
$blue:#3876d4;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

body{
	font-family: $montserrat;
	ul{
		list-style: none;
		padding: 0;
		margin: 0;
		> li{
			padding: 0;
			margin: 0;
		}
	}
	h1,h2,h3,h4,h5,h6,p,a{
		margin: 0;
		font-weight: 400;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}

a.user_friendly_click{
	@include fill;
}

div.vertical_height{
	@include fill;
	div{
		display: table;
		height: 100%;
		width: 100%;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

div.default_font{
	color: $font_color2;
	font-size: 18px;
	line-height: 28px;
	h1{
		font-size: 72px;
		line-height: 92px;
	}
	h2{
		font-size: 48px;
		line-height: 68px;
	}
	h3{
		font-size: 30px;
		line-height: 50px;
	}
	h4{
		font-size: 24px;
		line-height: 44px;
	}
	h5{
		font-size: 22px;
		line-height: 32px;
	}
	h6{
		font-size: 20px;
		line-height: 30px;
	}
	p{
		
	}
	strong{
		font-weight: 700;
	}
}

div.text_desc{
	div.default_font{
		font-family: $open_sans;
		h1,h2,h3,h4,h5,h6{
			color: $font_color1;
			font-family: $montserrat;
			font-weight: 500;
		}
		color: $font_color2;
		h1,h2,h3,h4,h5,h6,p,ul,a{
			padding-bottom: 10px;
			&:last-child{
				padding-bottom: 0;
			}
		}
		ul{
			li{
				position: relative;
				padding-left: 12px;
				&:before{
					content: '';
					top: 12px;
					left: 0;
					position: absolute;
					display: block;
					width: 5px;
					height: 4px;
					background-repeat: no-repeat;
					background-position: center;
					background-image: url('./../img/list_dot.png');
				}
			}
		}
		img{
			max-width: 100%;
			height: auto;
			margin-bottom: 10px;
		}
	}
}

a.more_button1{
	display: inline-block;
	padding: 28px 37px;
	background-color: $grey;
	color: $font_color1;
	font-size: 14px;
	text-transform: uppercase;
	font-weight: 700;
	font-family: $montserrat;
	transition: 0.3s all ease;
	&:hover{
		color: $grey;
		background-color: $font_color1;
	}
}

div.page{
	position: relative;
	overflow: hidden;
}

header.header{
	position: absolute;
	padding-top: 53px;
	left: 0;
	width: 100%;
	z-index: 10;
	div.logo_box{
		position: relative;
	}
	div.menu_box{
		padding-top: 21px;
		nav.menu{
			> ul{
				text-align: right;
				> li{
					display: inline-block;
					vertical-align: top;
					//margin-right: 5%;
					position: relative;
					padding-bottom: 24px;
					> div{
						position: relative;
						padding-right: 10px;
						margin-right: 10px;
						&:after{
							content: '';
							display: block;
							border-right: 2px solid #215a99;
							height: 100%;
							position: absolute;
							top: 0;
							right: -2.5%;
						}
					}
					&:hover{
						> ul{
							display: block;
						}
					}
					&:last-child{
						margin-right: 0;
						> div{
							&:after{
								display: none;
							}
						}
					}
					a{
						font-family: $montserrat;
						font-size: 16px;
						text-transform: uppercase;
						font-weight: 500;
						//color: #525a75;
						color: #215a99;
						transition: 0.3s all ease;
						&:hover{
							color: $blue;
						}
					}
					ul{
						display: none;
						-webkit-box-shadow: 0px 0px 44px 0px rgba(188,188,188,1);
						-moz-box-shadow: 0px 0px 44px 0px rgba(188,188,188,1);
						box-shadow: 0px 0px 44px 0px rgba(188,188,188,1);
						border: 1px solid #bcbcbc;
						z-index: 10;
						padding-top: 24px;
						text-align: center;
						position: absolute;
						top: 100%;
						left: -45%;
						margin-left: 10%;
						margin-right: 10%;
						right: -45%;
						background-color: $white;
						> li{
							margin-right: 0;
							margin-bottom: 24px;
							display: block;
							> a{
								font-weight: 300;
							}
						}
					}
					div.on{
						> a{
							color: $blue;
						}
					}
				}
			}
		}
	}
	div.header_phone{
		text-align: right;
		padding-top: 24px;
		ul{
			display: inline-block;
			padding: 29px 36px;
			background-color: $white;
			> li{
				display: inline-block;
				vertical-align: top;
				font-size: 16px;
				font-weight: 700;
				color: #525a75;
				position: relative;
				&:last-child{
					&:after{
						display: none;
					}
				}
				&:after{
					content: '/';
					font-size: 16px;
					font-weight: 700;
					color: $blue;
					margin-left: 6px;
					margin-right: 6px;
				}
			}
		}
	}
}

section.main_top{
	width: 100%;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	position: relative;
	padding-bottom: 3%;
	div.main_top_content{
		div.cars{
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 29%;
			div.car{
				background-repeat: no-repeat;
				background-position: center;
				background-size: cover;
			}
			div.car1{
				position: absolute;
				top: 0;
				left: 10%;
				width: 30%;
				height: 0;
				padding-bottom: 20%;
			}
			div.car2{
				position: absolute;
				top: 0;
				left: 45%;
				z-index: 2;
				width: 50%;
				height: 0;
				padding-bottom: 30%;
			}
		}
		div.desc{
			background-position: left center;
			background-repeat: no-repeat;
			background-image: url('./../img/separator.png');
			padding-left: 38px;
			div.default_font{
				color: #525a75;
				padding-left: 10px;
				font-family: $montserrat;
				strong{
					font-weight: 500;
					color: $blue;
				}
				h1{
					font-weight: 700;
					padding-left: 15px;
				}
				h2{
					font-weight: 500;
				}
			}
		}
		ul{
			padding-top: 20px;
			li{
				display: inline-block;
				vertical-align: middle;
				margin-right: 18px;
				font-family: $montserrat;
				div.default_font{
					color: #525a75;
					font-weight: 600;
					position: relative;
					/*&:after{
						content: '';
						width: 106%;
						border-bottom:2px solid $white;
						position: absolute;
						top: 100%;
						display: block;
						padding-top: 23px;
						left: 0;
					}*/
				}
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	div.french_cover{
		@include fill;
		background-position:right top;
		background-repeat: no-repeat;
		background-size: cover;
	}
}

section.home_about{
	padding: 3% 0;
	div.home_about_left{
		padding-right: 17%;
		div.text_desc{
			padding-bottom: 40px;
		}
		ul{
			margin-bottom: 40px;
			> li{
				display: inline-block;
				vertical-align: middle;
				&:first-child{
					margin-right: 65px;
				}
				img{
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
	div.home_about_right{
		div.text_desc{
			div.default_font{
				h3{
					padding-bottom: 40px;
				}
			}
		}
	}
	div.login_box{
		background-color: $grey;
		padding: 40px 58px;
		background-position: right bottom;
		background-repeat: no-repeat;
		background-image: url('./../img/login_box_corner.jpg');
		p{
			color: #525a75;
			padding-bottom: 18px;
			font-size: 16px;
		}
		div.holder{
			padding-bottom: 20px;
			input.input{
				background-color: $white;
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: 2px solid #e4e4e4;
				width: 100%;
				height: 70px;
				color: #525a75;
				font-family: $montserrat;
				padding: 0 20px;
				outline: none;
			}
		}
		input.submit{
			padding-left: 0;
			padding-right: 0;
			display: inline-block;
			padding-bottom: 0;
			font-size: 16px;
			color: #525a75;
			font-weight: 600;
			border-top: none;
			border-left: none;
			border-right: none;
			background-color: transparent;
			border-bottom: 2px solid #adb0b9;
		}
	}
}

section.on_market{
	padding: 4% 0 6% 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	div.row{
		div.text-center{
			padding-bottom: 4%;
			h1,h2,h3,h4,h5,h6,p{
				font-weight: 700;
				text-transform: uppercase;
			}
			strong{
				color: $blue;
			}
		}
		div.col-md-12{
			> ul{
				> li{
					display: inline-block;
					vertical-align: middle;
					width: 50%;
					> div.default_font{
						position: relative;
						padding-left: 37px;
						padding-right: 37px;
						&:before{
							content: '';
							display: block;
							border-left: 3px solid $blue;
							position: absolute;
							top: 0;
							left: 0;
							height: 100%;
						}
					}
				}
			}
		}
	}
}

section.home_contact{
	//padding: 3% 0;
	//padding: 20px 0;
	padding: 15px 0;
	text-align: center;
	background-color: $grey;
	div.home_contact_header{
		//padding-bottom: 3%;
		padding-bottom: 15px;
		div.default_font{
			color: $font_color1;
			h1,h2,h3,h4,h5,h6{
				font-weight: 500;
			}
		}
	}
	div.home_contact_phone{
		//padding-bottom: 3%;
		padding-bottom: 15px;
		ul{
			li{
				display: inline-block;
				vertical-align: middle;
				> div.default_font{
					h4{
						font-weight: 300;
						//padding-right: 33px;
						padding-right: 23px;
						background-repeat: no-repeat;
						background-position: right top 15px;
						background-image: url('./../img/blue_mark1.png');
						//margin-right: 33px;
						margin-right: 10px;
					}
				}
				div.phones{
					ul{
						border: 5px solid $white;
						padding: 20px 27px;
						li{
							display: inline-block;
							vertical-align: middle;
							&:last-child{
								div.default_font{
									h6{
										&:after{
											display: none;
										}
									}
								}
							}
							div.default_font{
								h6{
									font-weight: 700;
									color: $font_color1;
									position: relative;
									&:after{
										content: '/';
										color: $blue;
										display: block;
										margin: 0 8px;
										font-size: 20px;
										font-weight: 700;
										display: inline-block;
									}
								}
							}
						}
					}
				}
			}
		}	
	}
	div.home_contact_mail{
		div.default_font{
			p{
				font-weight: 300;
				a{
					font-weight: 600;
					color: $font_color1;
					text-decoration: underline;
				}
			}
		}
	}
}

section.carousel1{
	padding: 3% 0 6% 0;
	div.carousel_header{
		padding-bottom: 5%;
		div.default_font{
			margin-left: 50px;
			padding-left: 40px;
			background-repeat: no-repeat;
			background-position: left center;
			background-image: url('./../img/separator.png');
			h3{
				color: $font_color1;
			}
		}
	}
	div.col-md-1{
		padding-left: 0;
		padding-right: 0;
	}
	div.arr{
		padding-bottom: 57px;
		height: 0;
		width: 100%;
		background-repeat: no-repeat;
		background-size: contain;
		cursor: pointer;
	}
	div.arr_left{
		background-image: url('./../img/carousel_arr_left.png');
		background-position: center right;
	}
	div.arr_right{
		background-image: url('./../img/carousel_arr_right.png');
		background-position: center left;
	}
	div.carousel_logos{
		padding-top: 10px;
		overflow: hidden;
		> ul{
			position: relative;
			white-space: nowrap;
			> li{
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				width: 16.66%;
				//margin-right: 3.5%;
				img{
					max-width: 80%;
				}
			}
		}
	}
}

footer.footer{
	div.footer_top{
		padding: 15px 0;
		//padding: 20px 0;
		//padding-top: 3%;
		//padding-bottom: 3%;
		border-top: 1px solid #e4e4e4;
		div.footer_nav_header{
			p{
				color: $font_color1;
				font-weight: 700;
				font-size: 16px;
				line-height: 28px;
				//padding-bottom: 36px;
				padding-bottom: 10px;
			}
		}
		nav{
			ul{
				li{
					//padding-bottom: 20px;
					padding-bottom: 10px;
					&:last-child{
						padding-bottom: 0;
					}
					a{
						font-size: 14px;
						line-height: 14px;
						color: #525a75;
						font-family: $open_sans;
						transition: 0.3s all ease;
						font-weight: 400;
						&:hover{
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
	div.footer_bottom{
		//padding: 3% 0;
		//padding: 20px 0;
		padding: 15px 0 5px 0;
		background-color: $grey;
		p{
			font-size: 16px;
			//padding-bottom: 30px;
			padding-bottom: 10px;
			color: #525a75;
			a{
				color: #525a75;
			}
		}
		ul{
			li{
				display: inline-block;
				margin-right:-4px;
				vertical-align: top;
				&:nth-child(odd){
					width: 40%;
				}
				&:nth-child(even){
					width: 60%;
				}
				p{
					font-size: 14px;
					color: $font_color2;
					font-family: $open_sans;
					//padding-bottom: 15px;
					padding-bottom: 10px;
				}
			}
		}
	}
}

//podstrony

section.top{
	width: 100%;
	//height: 54.5vh;
	//min-height: 30.5vh;
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	div.top_content{
		background-position: left center;
		background-repeat: no-repeat;
		background-image: url('./../img/separator.png');
		padding-left: 38px;
		//margin-top: 3%;
		margin: 1.5% 0 2% 0;
		div.desc{
			width: 94%;
			div.default_font{
				color: $font_color1;
				//padding-left: 30px;
				h1{
					//font-size: 72px;
					//line-height: 92px;
					font-size: 32px;
					line-height: 32px;
					font-weight: 500;
				}
				h2{
					font-size: 46px;
					line-height: 66px;
					font-weight: 500;
				}
				h3{
					font-size: 39px;
					line-height: 59px;
					font-weight: 500;
				}
				h4{
					font-size: 24px;
					line-height: 44px;
					font-weight: 500;
				}
				h5{
					
				}
				h6{
					
				}
			}
		}
	}
	div.french_cover{
		@include fill;
		background-position:right top;
		background-repeat: no-repeat;
		background-size: 45% auto;
	}
}

section.about{
	padding: 3% 0;
	div.text_desc{
		padding-top: 6%;
		padding-bottom: 6%;
		div.default_font{
			padding-right: 5%;
			h1,h2,h3,h4,h5,h6{
				font-weight: 400;
			}
		}
	}
	ul{
		margin-bottom: 40px;
		> li{
			display: inline-block;
			vertical-align: middle;
			&:first-child{
				margin-right: 65px;
			}
			img{
				max-width: 200px;
				height: auto;
			}
		}
	}
	div.image{
		width: 100%;
		height: 0;
		padding-bottom: 75%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	div.map{
		width: 100%;
		height: 0;
		padding-bottom: 75%;
		position: relative;
		> iframe{
			@include fill;
		}
	}
}

a.return{
	cursor: pointer;
	transition: 0.3s all ease;
	color: #fff;
	display: inline-block;
	padding: 20px;
	width: 100%;
	text-align: center;
	border-radius: 10px;
	background-color: #215a99;
	margin-bottom: 15px;
	transition: 0.3s all ease;
	border: 2px solid #215a99;
	&:hover{
		background-color: $white;
		color: #215a99;
	}
}

section.only_text{
	padding-bottom: 3%;
}

section.split_view{
	padding: 20px 0;
	//padding: 3% 0;
	/*section.menu_left{
		padding: 38px 54px;
		background-color: #f5f8fd;
		div.default_font{
			padding-bottom: 25px;
		}
		nav{
			ul{
				li{
					padding-bottom: 25px;
					a{
						font-size: 18px;
						color: #7a7f93;
						font-family: $open_sans;
						transition: 0.3s all ease;
						&:hover{
							padding-left: 10px;
						}
					}
				}
			}
		}
	}*/
	section.menu_left{
		nav{
			> ul{
				> li{
					a{
						transition: 0.3s all ease;
						color: #fff;
						display: inline-block;
						padding: 20px;
						width: 100%;
						text-align: center;
						border-radius: 10px;
						background-color: #215a99;
						margin-bottom: 15px;
						transition: 0.3s all ease;
						border: 2px solid #215a99;
						&:hover{
						background-color: $white;
						color: #215a99;
						}
					}
				}
			}
		}
	}
	div.desc_right{
		//padding-left: 10%;
		div.text_desc{
			div.default_font{
				h1,h2,h3,h4,h5,h6,p{
					font-weight: 400;
				}
			}
		}
		div.logotypes{
			padding-top: 50px;
			> ul{
				> li{
					display: inline-block;
					margin-bottom: 50px;
				}
			}
		}
	}
	div.right_image{
		width: 100%;
		height: 0;
		padding-bottom: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;
	}
}

section.components{
	padding: 3% 0;
	section.menu_left{
		//padding: 38px 54px;
		padding: 45px 25px;
		background-color: #f5f8fd;
		div.col-md-6{
			a{
				padding-top: 10px;
				display: inline-block;
				font-size: 14px;
				color: $font_color2;
				cursor: pointer;
			}
		}
		nav{
			display: none;
			padding-top: 25px;
			ul{
				li{
					padding-bottom: 25px;
					a{
						font-size: 18px;
						color: #7a7f93;
						font-family: $open_sans;
						transition: 0.3s all ease;
						&:hover{
							padding-left: 10px;
						}
					}
				}
			}
		}
	}
	div.desc_right{
		padding-top: 38px;
	}
}

section.products{
	div.products_list{
		ul{
			li{
				display: inline-block;
				vertical-align: top;
				border: 1px solid #e3e9ed;
				padding-bottom: 3%;
				width: 30%;
				margin-right: 5%;
				margin-bottom: 5%;
				overflow: hidden;
				position: relative;
				&:nth-child(3n){
					margin-right: 0;
				}
				&:hover{
					div.image{
						transform: scale(1.1);
					}
				}
				div.product{
					div.image{
						width: 100%;
						height: 0;
						padding-bottom: 66%;
						background-position: center;
						background-repeat: no-repeat;
						background-size: cover;
						transition: 0.3s all ease;
					}
					div.desc{
						div.default_font{
							text-align: center;
							color: $font_color1;
							h4{
								font-weight: 600;
							}
						}
					}
				}
			}
		}
	}
}

section.details{
	&.main_category{
		div.details_box{
			position: relative;
		}
	}
	div.industry_desc{
		padding-bottom: 5%;
		div.text_desc{
			div.default_font{
				padding-left: 19px;
				padding-top: 40px;
			}
		}
		&.category{
			padding-bottom: 0;
			div.image{
				width: 100%;
				height: 0;
				padding-bottom: 100%;
				background-position: center;
				//background-size: contain;
				background-size: cover;
				background-repeat: no-repeat;
			}
			div.text_desc{
				div.default_font{
					padding: 0;
					h1,h2,h3,h4,h5,h6,p,a,ol,ul{
						padding-bottom: 15px;
						&:last-child{
							padding-bottom: 0;
						}
					}
				}
			}
		}
	}
	div.details_box{
		margin-bottom: 20px;
		//margin-bottom: 6%;
		padding: 20px;
		//padding: 45px 41px;
		background-color: #f5f5f5;
		border: 1px solid #e2e2e2;
		> div.row{
			margin-bottom: 40px;
			&:last-child{
				margin-bottom: 0;
			}
		}
		div.desc_left{
			div.image{
				text-align: right;
			}
		}
		div.image{
			img{
				max-width: 100%;
				height: auto;
			}
		}
		div.image2{
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			width: 100%;
			height: 0;
			padding-bottom: 74%;
			position: relative;
		}
		div.categories{
			div.text_desc{
				div.default_font{
					ul{
						padding-left: 35px;
					}
				}
			}
		}
		div.row.categories{
			margin-bottom: 30px;
		}
	}
	div.links{
		ul{
			li{
				display: inline-block;
				vertical-align: top;
				margin-right: 18px;
				&:last-child{
					margin-right: 0;
				}
				p{
					font-size: 14px;
					color: $font_color2;
					a{
						display: inline-block;
						font-size: 14px;
						text-decoration: underline;
						color: $font_color2;
					}
				}
			}
		}
	}
	table{
		margin-bottom: 40px;
		font-family: $open_sans;
		width: 100%;
		border: 1px solid #e2e2e2;
		tr{
			td{
				border-right: 1px solid #e2e2e2;
				//width: auto;
			}
		}
		> thead{
			background-color: $blue;
			> tr{
				> td{
					text-align: center;
					height: 70px;
					color: $white;
					font-weight: 700;
					font-size: 20px;
				}
			}
		}
		>tbody{
			>tr{
				&:nth-child(odd){
						background-color: $white;
					}
					&:nth-child(even){
						background-color: #e2e2e2;
					}
				>td{
					text-align: center;
					height: 70px;
					font-weight: 400;
					font-size: 18px;
					color: $font_color2;
				}
			}
		}
	}
}

section.contact{
	padding: 3% 0;
	div.col-md-12{
		padding-bottom: 3%;
	}
	div.contact_left{
		div.default_font{
			font-family: $open_sans;
			h1,h2,h3,h4,h5,h6{
				font-weight: 400;
			}
			p{
				padding-bottom: 20px;
			}
		}
	}
	div.map{
		height: 500px;
		width: 100%;
		position: relative;
		border: 1px solid #999999;
		> iframe{
			@include fill;
		}
	}
}

//rwd

@media only screen and (min-width: 992px) and (max-width: 10000px) {
	div.menu_box{display: block!important;}
}

@media only screen and (min-width: 1500px) and (max-width: 1820px) {
	section.main_top div.french_cover{left: auto; right: -10%; width: 110%;}
	//section.top div.french_cover{left: auto; right: -10%; width: 110%;}
	section.top div.top_content div.desc{width: 90%;}
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
	section.main_top div.french_cover{left: auto; right: -10%; width: 120%;}
	//section.top div.french_cover{left: auto; right: -20%; width: 120%;}
	
	section.top div.top_content div.desc{width: 90%;}
	
	//header.header div.menu_box nav.menu > ul > li{margin-right: 4%;}
	section.main_top div.main_top_content div.desc div.default_font h1{font-size: 42px; line-height: 52px;}
	section.main_top div.main_top_content div.desc div.default_font h2{font-size: 28px; line-height: 38px;}
	
	header.header{padding-top: 25px; padding-bottom: 25px;}
	
	section.top div.top_content div.desc div.default_font h1{font-size: 42px; line-height: 52px;}
	section.top div.top_content{margin: 1.5% 0 2% 0; padding-left: 28px;}
	
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	section.main_top div.french_cover{left: auto; right: -17%; width: 130%;}
	section.top div.french_cover{left: auto; right: -30%; width: 130%;}
	
	section.main_top div.main_top_content div.desc div.default_font h1{font-size: 61px; line-height: 81px;}
	section.main_top div.main_top_content div.desc div.default_font h2{font-size: 41px; line-height: 61px;}
	section.main_top div.main_top_content ul li div.default_font h5{font-size: 18px; line-height: 28px;}
	
	section.top div.main_top_content div.desc div.default_font h1{font-size: 61px; line-height: 81px;}
	section.top div.main_top_content div.desc div.default_font h2{font-size: 41px; line-height: 61px;}
	section.top div.top_content div.desc div.default_font h3{font-size: 25px; line-height: 45px;}
	section.top div.top_content div.desc div.default_font h4{font-size: 20px; line-height: 40px;}
	section.top div.top_content div.desc div.default_font h5{font-size: 18px; line-height: 28px;}
	section.top div.top_content div.desc div.default_font h6{font-size: 15px; line-height: 25px;}
	
	header.header div.logo_box img{max-width: 100%; height: auto;}
	
	section.main_top div.main_top_content img{max-height: 30px!important;}
	header.header div.menu_box nav.menu > ul > li ul{padding-top: 14px;}
	header.header div.menu_box nav.menu > ul > li ul > li{margin-bottom: 11px;}
	header.header div.menu_box{padding-top: 11px;}
	header.header div.logo_box img{width: 200px; height: auto;}
	//header.header div.menu_box nav.menu > ul > li{margin-right: 3%;}
	header.header div.menu_box nav.menu > ul > li a{font-size: 13px;}
	section.main_top div.main_top_content div.desc div.default_font h1{font-size: 42px; line-height: 52px;}
	section.main_top div.main_top_content div.desc div.default_font h2{font-size: 28px; line-height: 38px;}
	
	header.header{padding-top: 25px; padding-bottom: 25px;}
	
	section.top div.top_content div.desc div.default_font h1{font-size: 42px; line-height: 52px;}
	section.top div.top_content{margin: 1.5% 0 2% 0; padding-left: 28px;}
	
	header.header div.langs{padding-top: 5px!important;}
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
	section.home_contact div.home_contact_phone ul li{display: block;}
	section.home_contact div.home_contact_phone ul li > div.default_font h4{background-image: none; padding-right: 0; margin-right: 0;}
	section.home_contact div.home_contact_phone ul li div.phones ul li div.default_font h6:after{display: none;}
	section.home_contact div.home_contact_phone ul li div.phones ul li{display: block;}
	
	section.products div.products_list ul li{width: 100%; margin-right: 0; margin-bottom: 30px;}
	
	section.details table > thead > tr > td{font-size: 10px;}
	section.details table > tbody > tr > td{font-size: 10px;}
	
	section.details div.details_box{padding: 25px 21px;}
	section.details div.details_box > div.row{margin-bottom: 20px;}
}

@media only screen and (min-width: 320px) and (max-width: 991px) {
	div.french_cover{display: none;}
	div.logo_box img{width: 120px!important; height: auto!important;}
	div.rwd_button{width: 30px; position: absolute; top: 5px; right: 0;}
	div.rwd_button > div{width: 100%; height: 3px; margin-bottom: 3px;}
	div.rwd_button > div:nth-child(1){background-color: #3876d4;}
	div.rwd_button > div:nth-child(2){background-color: #ffffff;}
	div.rwd_button > div:nth-child(3){background-color: #f44141;}
	div.rwd_button > div:last-child{margin-bottom: 0;}
	
	header.header{padding-top: 10px; padding-bottom: 10px; z-index: 5; position: fixed; left: 0; top: 0; width: 100%; background-color: $grey;}
	header.header div.menu_box nav.menu > ul{text-align: center;}
	header.header div.menu_box nav.menu > ul > li{width: 100%; margin-right: 0; padding-bottom: 15px;}
	header.header div.menu_box nav.menu > ul > li:last-child{margin-bottom: 0; padding-bottom: 0;}
	header.header div.menu_box nav.menu > ul > li > ul{display: block; position: relative; width: 100%; left: auto; right: auto; margin-left: 0; margin-right: 0; border: none; padding: 0; box-shadow: none; background-color: transparent; padding-top: 15px;}
	header.header div.menu_box nav.menu > ul > li ul > li{margin-bottom: 0; padding-bottom: 15px;}
	header.header div.menu_box nav.menu > ul > li ul > li:last-child{padding-bottom: 0;}
	header.header div.header_phone{display: none;}
	header.header div.header_phone{text-align: center;}
	header.header div.menu_box{display: none;}
	
	section.main_top div.main_top_content div.desc{background-image: none; padding-left: 0;}
	section.main_top div.main_top_content div.desc div.default_font {text-align: center;}
	section.main_top div.main_top_content div.desc div.default_font h1{padding-left: 0;}
	section.main_top div.main_top_content ul li div.default_font:after{display: none;}
	section.main_top div.main_top_content ul{text-align: center;}
	section.main_top div.main_top_content ul li{margin-right: 0; margin-bottom: 15px;}
	section.main_top div.main_top_content ul li:last-child{margin-bottom: 0;}
	section.main_top div.main_top_content div.cars div.car{top: 0!important;}
	section.main_top div.main_top_content div.cars div.car1{left: 20%!important;}
	section.main_top div.main_top_content div.cars div.car2{left: 35%!important;}
	
	section.home_about{padding: 30px 0;}
	section.home_about div.home_about_left{padding-left: 0; padding-right: 0;}
	section.home_about div.login_box{padding: 30px 30px;}
	section.home_about div.home_about_left ul{text-align: center; margin-bottom: 0;}
	section.home_about div.home_about_left ul > li:first-child{margin-right: 0;}
	section.home_about div.home_about_left div.text_desc{padding-bottom: 0;}
	section.home_about div.home_about_left{text-align: center;}
	div.home_about_right{padding-top: 20px;}
	div.home_about_right div.default_font{text-align: center;}
	
	section.on_market div.row div.col-md-12 > ul > li{width: 100%;}
	
	section.carousel1{padding-top: 30px; padding-bottom: 30px;}
	section.carousel1 div.carousel_header div.default_font{margin-left: 0; padding-left: 0; background-image: none; text-align: center;}
	section.carousel1 div.col-md-1{display: none;}
	
	footer.footer div.footer_top div.col-md-3{padding-bottom: 15px;}
	footer.footer div.footer_top{padding: 15px 0 0 0; text-align: center;}
	footer.footer div.footer_top div.footer_nav_header p{padding-bottom: 10px;}
	footer.footer div.footer_bottom{padding: 15px 0 5px 0; text-align: center;}
	footer.footer div.footer_bottom ul{text-align: center; padding-bottom: 0;}
	footer.footer div.footer_bottom ul li{width: 100%!important;}
	footer.footer div.footer_bottom ul li p{font-size: 10px;}
	div.devs.text-right{text-align: center;}
	
	div.default_font h1{font-size: 32px; line-height: 52px;}
	div.default_font h2{font-size: 28px; line-height: 48px;}
	div.default_font h3{font-size: 25px; line-height: 45px;}
	div.default_font h4{font-size: 20px; line-height: 40px;}
	div.default_font h5{font-size: 18px; line-height: 28px;}
	div.default_font h6{font-size: 15px; line-height: 25px;}
	div.default_font{font-size: 13px; line-height: 23px;}
	div.text_desc{
		div.default_font{
			h1,h2,h3,h4,h5,h6,p,ul,ol,a{
				padding-bottom: 20px!important;
			}
		}
	}
	
	section.about div.text_desc div.default_font{padding-right: 0;}
	section.about div.text_desc div.default_font br{display: none;}
	section.about ul > li:first-child{margin-right: 0;}
	section.about div.col-md-6 > ul{text-align: center;}
	
	section.top{height: auto;}
	section.top div.top_content{padding-left:0; background-image: none; }
	section.top div.top_content div.desc div.default_font{padding-left: 0; text-align: center;}
	section.top div.top_content div.desc{width: 100%;}
	section.top div.top_content div.desc div.default_font h1{font-size: 32px; line-height: 52px;}
	section.top div.top_content div.desc div.default_font h2{font-size: 28px; line-height: 48px;}
	section.top div.top_content div.desc div.default_font h3{font-size: 25px; line-height: 45px;}
	section.top div.top_content div.desc div.default_font h4{font-size: 20px; line-height: 40px;}
	section.top div.top_content div.desc div.default_font h5{font-size: 18px; line-height: 28px;}
	section.top div.top_content div.desc div.default_font h6{font-size: 15px; line-height: 25px;}
	
	section.menu_left {text-align: center;}
	section.menu_left div.text-right {text-align: center;}
	section.components section.menu_left{padding: 20px 21px 0 20px;}
	
	div.text-right{text-align: center;}
	
	section.contact div.map{height: 200px;}
	section.contact{padding: 30px 0; text-align: center;}
	
	div.table{
		width: 100%;
		overflow: scroll;
	}
	
	div.text_desc div.default_font img{display: block; margin-left: auto; margin-right: auto;}
	
	div.industry_desc div.image{text-align: center; margin-bottom: 20px;}
	section.details div.industry_desc div.text_desc div.default_font{padding-left: 0;}
	
	section.details div.details_box div.desc_left div.image{text-align: center;}
	section.details div.details_box div.image{text-align: center;}
	section.details div.details_box div.categories div.text_desc div.default_font ul{padding-left: 0;}
	
	section.split_view section.menu_left{padding: 21px 20px; margin-bottom: 30px;}
	section.split_view section.menu_left nav ul li:last-child{padding-bottom: 0;}
	
	section.split_view div.desc_right{padding-left: 0;}
	
	section.home_about div.home_about_left ul > li{display: block;}
	section.about ul > li{display: block;}
	
	section.main_top div.main_top_content ul li{width: 100%;}
	section.main_top div.main_top_content img{max-height: 25px!important;}
	
	header.header div.menu_box nav.menu > ul > li ul{display: none!important;}
	
	header.header div.langs{text-align: right;}
	.dropdown-menu{left: auto!important; right: 0;}
	
	header.header div.menu_box nav.menu > ul > li > div{padding-right: 0; margin-right: 0;}
	header.header div.menu_box nav.menu > ul > li > div:after{display: none;}
}

header.header{
	div.langs{
		padding-top: 12px;
	}
	.dropdown-menu{
		.btn-primary{
			background-color: #3876d4;
			border-color: #3876d4;
		}
		text-align: center;
		min-width: 53.34px;
		> li{
			> a{
				padding: 3px 0;
			}
		}
	}
}
/*
section.category{
	padding: 3% 0;
	div.category_list{
		ul{
			> li{
				width: 31%;
				display: inline-block;
				vertical-align: top;
				margin-right: 3.5%;
				margin-bottom: 3.5%;
				position: relative;
				&:nth-child(3n){
					margin-right: 0;
				}
				div.image{
					width: 100%;
					height: 0;
					padding-bottom: 100%;
					background-color:rebeccapurple;
				}
				div.text_desc{
					background-color: $white;
					div.default_font{
						padding: 20px;
						h1,h2,h3,h4,h5,h6,p{
							padding-bottom: 15px;
							&:last-child{
								padding-bottom: 0;
							}
						}
					}
				}
			}
		}
	}
}
*/